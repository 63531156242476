import React from "react";
const DiscSpotify = (props) => {
  return (
    <div className="discSpotify basePad" style={{height: '100%', width: '100%'}}>
      <div className="spotifyWrapper">
          <iframe src= {props.post.spotify}
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
            margin="0"
            padding= "0"
            height= "100%"
            width="100%"
            allowFullScreen="" style={{position: "absolute", top:"0", left: "0"}}>
          </iframe>
      </div>
    </div>
  )
}
export default DiscSpotify;
