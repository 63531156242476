import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Seo from "../components/modules/Seo";
import DiscInfo from '../components/modules/DiscInfo';
import DiscSpotify from "../components/modules/DiscSpotify";
import config from "../../data/SiteConfig";

export default class DiscographyTemplate extends React.Component {
  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }
    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }
    return (
      <Layout>
        <div className="disc flex center column">
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <Seo postPath={slug} postNode={postNode} postSEO />
          <div className="discInfo">
            <DiscInfo post={post} postNode={postNode}/>
            <DiscSpotify post={post}/>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query DiscPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        img {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 340)
          }
        }
        artists
        date
        category
        buylink
        spotify
      }
      fields {
        slug
        date
      }
    }
  }
`;
