import React, {Fragment} from 'react';
import DiscHero from "./DiscHero";
import { useWindowDimensions } from '../context/WindowDimensionsProvider';

const DiscInfo = (props) => {
  const { windowHeight, footerHeight } = useWindowDimensions()
  return (
    <>
    <div className="flex center column" style={{minHeight: windowHeight-footerHeight}}>
      <DiscHero
        fixed={props.post.img.childImageSharp.gatsbyImageData}
        title={props.post.title}
        artists={props.post.artists} />

      <div className="flex column basePad discText" style={{paddingBottom: "0", width:'100%'}}>
        <button role="button" style={{border: '0', padding: '0', margin: '0 0 1rem', alignSelf: "flex-start", backgroundImage: "repeating-linear-gradient(135deg, #034034, #034034 1px, #023020 1px, #023020 30px)"}}>
        <a href={props.post.buylink}>BUY CD</a></button>
        <div dangerouslySetInnerHTML={{ __html: props.postNode.html }} />
      </div>
    </div>
  </>
  )
}
export default DiscInfo;
